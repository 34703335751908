<template>
  <Modal
    :ui="{ close: { base: 'hidden' }, root: { color: 'bg-plashka-green shadow-[0_5px_14px_0] ring-1 ring-#f1f1f1 ring-inset shadow-black/8', addition: 'flex flex-col gap-4 max-w-83', padding: 'p-6 pt-5' } }"
    @close="emits('close')"
  >
    
<FormKitLazyProvider config-file="true" :default-config="false">
<button
      class="absolute top-0 right-0 flex top-4.5 right-4.5"
      @click="emits('close')"
    >
      <span class="i-custom:x text-firmennyy text-3"></span>
    </button>
    <h2 class="text-tekstovyy text-5.5 font-700">Отмена заказа</h2>
    <FormKit
      type="form"
      :actions="false"
      :incomplete-message="false"
      form-class="flex flex-col gap-5"
      @submit="(value) => handleDecline(value)"
    >
      <FormKit
        type="textarea"
        name="description"
        label="Комментарий"
        aria-required="true"
        label-class="$remove:text-tekstovyy text-#718997"
        inner-class="$remove:bg-#ebefe7 bg-white $remove:border-#eef2e5 border-#ebf0f2"
        input-class="h-20 $remove:placeholder:text-firmennyy/50 placeholder-seryy/50"
        placeholder="Напишите причину отмены"
      >
      </FormKit>
      <Button type="submit">Отменить</Button>
    </FormKit>
</FormKitLazyProvider>

  </Modal>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { vMaska } from 'maska/vue';
import { usePartnerOrders } from '~/composables/partner/orders/usePartnerOrders';
const { $toast } = useNuxtApp();
const { changeOrderStatus } = usePartnerOrders();
const props = defineProps<{
      order_id:number
  }>();

const handleDecline = async (form: {
    description: string
  }) => {
  console.log(form);
  const res = await changeOrderStatus({ order_id: props.order_id, status_description: form.description, status: 6 }).catch((err) => {
    $toast.error(err.message);
  });
  if (res) {
    emits('update-list');
    emits('close');
  }
};
const emits = defineEmits(['close', 'update-list']);
</script>
